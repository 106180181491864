import React from 'react';
import './Aboutprofile.css'; // Ensure the styles are in this CSS file

const Aboutprofile = () => {
  return (
    <div className="Combocoursefeat4">
      <h2 className="title">
      Our <span className="highlight"> Proven Process</span> for Unlocking Career Opportunities
      </h2>
      <div className="steps-container">
        <div className="step profile-building">
          <h3>Professional Profile Enhancement</h3>
          <ul>
            <li>✅ Crafting impactful resumes</li>
            <li>✅ Optimizing LinkedIn presence</li>
            <li>✅ Establishing profiles on key platforms</li>
          </ul>
        </div>
        <div className="step mock-interviews">
          <h3>Interview Preparation</h3>
          <ul>
            <li>✅ Identifying areas for improvement</li>
            <li>✅ Conducting mock problem-solving and design sessions</li>
            <li>✅ Tailored interview simulations for specific companies</li>
          </ul>
        </div>
        <div className="step right-opportunities">
          <h3>Connecting with Opportunities</h3>
          <ul>
            <li>✅ Access to roles in over 100 partner companies</li>
            <li>✅ Personalized referrals to leading product firms</li>
            <li>✅ Regular updates on active job openings</li>
          </ul>
        </div>
      </div>
      {/* <div className="image-section">
        <img src="/path-to-your-image.png" alt="Person working on laptop" />
      </div> */}
    </div>
  );
};

export default Aboutprofile;
