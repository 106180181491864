import React from "react";
import "./Whychcombo.css"; // Assuming you create a separate CSS file for styles

const Whychcombo = () => {
  return (
    <div className="Whychcombo">
      <h2 className="Whychcombo-title">Why to Choose <span className="highlight"> Bluink360?</span></h2>
      <div className="Whychcombo-container">
        <div className="Whychcombo-trainers">
          <img src="https://img.icons8.com/?size=150&id=5kw5Gph8BKMS&format=png&color=000000" alt="Trainers" className="Whychcombo-icon" />
          <h3>Well Experienced Trainers</h3>
          <p>Highly skilled trainers with 15+ years of rich experience in the IT industry.</p>
        </div>
        <div className="Whychcombo-flexible">
          <img src="https://img.icons8.com/?size=100&id=PDgJoygWSQQF&format=png&color=000000" alt="Flexible Training" className="Whychcombo-icon" />
          <h3>Flexible Classroom & Online Training</h3>
          <p>Comprehensive course duration, Flexible schedule and Training pattern.</p>
        </div>
        <div className="Whychcombo-realtime">
          <img src="https://img.icons8.com/?size=100&id=104230&format=png&color=000000" alt="Real-time Training" className="Whychcombo-icon" />
          <h3>Real-time Project Oriented Training</h3>
          <p>Well-designed course syllabus with Interactive instructor-led Training.</p>
        </div>
        <div className="Whychcombo-labsupport">
          <img src="https://img.icons8.com/?size=100&id=01Akfqm-En_q&format=png&color=000000" alt="Lab Support" className="Whychcombo-icon" />
          <h3>Unlimited Lab Support</h3>
          <p>Every individual is given the training with necessary Practicals & Projects.</p>
        </div>
        <div className="Whychcombo-affordable">
          <img src="https://img.icons8.com/?size=100&id=DIgy7KCtkTYA&format=png&color=000000" alt="Affordable Fees" className="Whychcombo-icon" />
          <h3>Affordable Fees with High Quality</h3>
          <p>We owe you knowledge at a very affordable fee structure.</p>
        </div>
        <div className="Whychcombo-certification">
          <img src="https://img.icons8.com/?size=100&id=-ADHqU46QZdK&format=png&color=000000" alt="Certification Guidance" className="Whychcombo-icon" />
          <h3>Certification Guidance</h3>
          <p>Interview preparation, Mock Interviews, Placements, Certification Assistance, etc.</p>
        </div>
        <div className="Whychcombo-jobassistance">
          <img src="https://img.icons8.com/?size=100&id=39833&format=png&color=000000" alt="Job Assistance" className="Whychcombo-icon" />
          <h3>Job Assistance & Guidance</h3>
          <p>Individual Job Counselor to assist with Career guidance & Job updates.</p>
        </div>
        <div className="Whychcombo-happystudents">
          <img src="https://img.icons8.com/?size=100&id=2R06yDrM7YbU&format=png&color=000000" alt="Happy Students" className="Whychcombo-icon" />
          <h3>Happy Students</h3>
          <p>We have 1000+ Happy Students with their satisfied reviews.</p>
        </div>
      </div>
    </div>
  );
};

export default Whychcombo;
