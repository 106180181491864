import React, { useRef } from "react";
import "./Ourcollabration.css";
import p1 from './collabrationimage/p1.png';
import p2 from './collabrationimage/p2.png';
import p3 from './collabrationimage/p3.png';
import p4 from './collabrationimage/p4.png';
import p5 from './collabrationimage/p5.png';
import p6 from './collabrationimage/p6.png';

const alumniData = [
  { name: '', img: p1 },
  { name: '', img: p2 },
  { name: '', img: p3 },
  { name: '', img: p4 },
  { name: '', img: p5 },
  { name: '', img: p6 },
];

const Ourcollabration = () => {
  const scrollerRef = useRef(null);

  const handleScroll = (direction) => {
    const scroller = scrollerRef.current;
    const scrollAmount = 300; // Adjust the scroll distance as needed
    if (direction === "left") {
      scroller.scrollLeft -= scrollAmount;
    } else {
      scroller.scrollLeft += scrollAmount;
    }
  };

  return (
    <div className="Hiringptcard">
      <h2 className="Hiringptcard-h2">
        Our <span>Collaborations</span> with <span>Innovators</span>
      </h2>
      <div className="Hiringptcard-container">
       
        <div className="Hiringptcard-scroller" ref={scrollerRef}>
          <div className="Hiringptcard-scroller__inner">
            {alumniData.map((e, index) => (
              <div key={index} className="Hiringptcard-student">
                <img className="Hiringptcard-img" src={e.img} alt={e.name} />
              </div>
            ))}
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Ourcollabration;
