import React from "react";
import "./Tech.css"; // CSS for styling
import cyber from "../../Assets/cyber.jpg";
import cyber2 from "../../Assets/cloud.jpg";
import ml from "../../Assets/ml.jpg";
import pbi from "../../Assets/pbi.png";
import Bigdata from "../../Assets/bigdata.jpg";
import datasci from "../../Assets/datasci.jpg";
import micro365 from "../../Assets/micro365.jpg";
import msd365 from "../../Assets/msd365.jpg";
import ai from "../../Assets/ai.jpg";
import itsm from "../../Assets/itsm.jpg";
import sap from "../../Assets/sap.png";
import dev from "../../Assets/dev.jpg";
import block from "../../Assets/block.jpg";
import digimark from "../../Assets/digimark.jpg";
import pmang from "../../Assets/pmang.jpg";
import busint from "../../Assets/busint.jpg";

const skills = [
  { src: cyber },
  { src: cyber2 },
  { src: ml },
  { src: pbi },
  { src: Bigdata },
  { src: datasci },
  { src: busint },
  { src: micro365 },
  { src: msd365 },
  { src: ai },
  { src: itsm },
  { src: sap },
  { src: dev },
  { src: block },
  { src: digimark },
  { src: pmang },
];

const Tech = () => {
  return (
    <section className="tech-section">
      <h1 id="Tools">Technologies We Cover</h1>
      <p id="techp"> 
        Master Cutting-edge Technologies With our Experts-led training in AI ,
        Cloud ,CyberSecurity , Data Science, and More
      </p>
      <div className="tech-grid">
        {skills.map((skill, index) => (
          <div className="tech-card" key={index}>
            <img src={skill.src} alt="" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Tech;
