import React from "react";
import './corpfeat1.css';

const CorpFeatures = () => {
    const content = [
        
            {
              "title": "Client-Centric Training Design",
              "description": "Bluink360 focuses on understanding client-specific challenges and goals to design training programs that address real-world needs and drive impactful results."
            },
            {
              "title": "Expert Trainers Across Industries",
              "description": "Our trainers bring diverse industry expertise, ensuring training content is relevant and aligns with the specific demands and regulations of various sectors."
            },
            {
              "title": "Focus on Engagement and Practical Learning",
              "description": "Bluink360 emphasizes hands-on learning with interactive tools, simulations, and real-world applications to ensure high engagement and retention."
            },
            {
              "title": "Result-Oriented Training",
              "description": "We set clear learning objectives and use metrics and feedback to evaluate progress, ensuring participants gain skills that directly translate into workplace performance."
            },
            {
              "title": "Commitment to Excellence",
              "description": "At Bluink360, we ensure the highest quality training experience by continually updating content and methodologies to meet the latest industry standards."
            },
            {
              "title": "Post-Training Support",
              "description": "We provide extended support for 3 to 5 months post-training, including follow-up sessions, resources, and performance assessments to reinforce learning outcomes."
            }
          
          
      ];
    
      return (
        <div className="WhatMakeusdiffer">
          <h2 className="WhatMakeusdiffer-title">
            Bluink360's Training Solution Includes :
          </h2>
          <div className="WhatMakeusdiffer-grid">
            {content.map((item, index) => (
              <div key={index} className="WhatMakeusdiffer-card">
                <h3 className="WhatMakeusdiffer-card-title">{item.title}</h3>
                <p className="WhatMakeusdiffer-card-description">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    };
    
export default CorpFeatures;
