import React from "react";
import "./AboutFounder.css";

const AboutFounder = () => {
  return (
    <div className="aboutfounder-container">
      {/* <h3 className="aboutfounder-title">Our Office Culture</h3>
      <div className="aboutfounder-image">
        <div className="balloon-container">
          <div className="balloon balloon1"></div>
          <div className="balloon balloon2"></div>
          <div className="balloon balloon3"></div>
        </div>
        <img
          src="https://lh3.googleusercontent.com/p/AF1QipP7sEI52z2D-_ilCJXnuTuRsmFpllPYQHmrB9ZS=s1360-w1360-h1020"
          alt="Bluink360 Team"
          className="aboutfounder-team-image"
        />
      </div> */}
      <div className="aboutfounder-description">
        <h3 className="aboutfounder-titles">About the Company</h3>
        <p className="aboutfounder-paragraph">
          Bluink360 Solution is a technology-driven company dedicated to
          empowering businesses and individuals through innovative solutions and
          exceptional services. 
        
          Our mission is to bridge the gap between innovation and implementation
          by providing cutting-edge technologies, expert training programs, and
          a robust support ecosystem tailored to your needs.
      
          Since our inception, we have collaborated with numerous organizations,
          delivering transformative solutions that drive growth and efficiency.
       
          At Bluink360 Solution, we thrive on challenges, fostering a culture of
          creativity, commitment, and excellence to help you achieve your
          ambitions.
        </p>
        <h3 className="aboutfounder-titles">Our Mission</h3>
<p className="aboutfounder-paragraph">
  To empower businesses and individuals with innovative technology solutions
  and skill-enhancement programs that drive efficiency, foster growth, and
  unlock potential. By integrating cutting-edge technologies, creative
  strategies, and a customer-centric approach, we aim to deliver excellence
  in both business services and professional development, including
  specialized courses in Data Structures and Algorithms (DSA).
</p>

<h3 className="aboutfounder-titles">Our Vision</h3>
<p className="aboutfounder-paragraph">
  To be a global leader in technology, innovation, and skill enhancement,
  recognized for transforming ideas into impactful solutions that shape the
  future. We envision a world where businesses thrive through our
  sustainable, scalable digital solutions, and individuals excel through
  industry-relevant programs like our DSA skill enhancement course,
  empowering them to tackle real-world challenges with confidence.
</p>

      </div>
    </div>
  );
};

export default AboutFounder;
