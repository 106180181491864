import Accordion from "react-bootstrap/Accordion";
import "./Dsa.css";

function ProgramDsa() {
  return (
    <div className="stu">
      <h1 id="stu1">
        Program Structure - <span> MERN Stack + DSA & System Design</span>
      </h1>
      <p id="stup">
        The following syllabus is designed to help you become proficient in MERN
        Stack Development, Data Structures & Algorithms (DSA), and System
        Design, along with the necessary tools and software used in the
        industry.
      </p>

      <h1 id="stu2">
        MERN Stack Development
        <span> </span>
      </h1>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Module 1: Introduction to Web Development
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Understanding Web Development: Frontend vs Backend</li>
              <li>Basics of HTML, CSS, JavaScript</li>
              <li>Introduction to Node.js and NPM (Node Package Manager)</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Module 2: MongoDB (Database)</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">
                Tools: MongoDB, MongoDB Compass, Mongoose ORM
              </li>
              <ul>
                <li>Introduction to NoSQL Databases</li>
                <li>CRUD Operations (Create, Read, Update, Delete)</li>
                <li>MongoDB Architecture and Schema Design</li>
                <li>MongoDB Atlas and Cloud Integration</li>
                <li>Advanced Queries and Indexing</li>
                <li>Data Modeling and Aggregation Framework</li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Module 3: Express.js (Backend Framework)
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">
                Tools: Postman for API Testing, Nodemon for auto-reload
              </li>
              <ul>
                <li>Introduction to Express.js and its Role</li>
                <li>Middleware in Express</li>
                <li>Routing and Handling HTTP Requests</li>
                <li> Error Handling and Validation</li>
                <li>Working with RESTful APIs</li>
                <li> Authentication and Authorization (JWT, OAuth)</li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        {/* New Modules */}
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Module 4: React.js (Frontend Framework)
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">
                Tools: React Developer Tools, Create React App
              </li>
              <ul>
                <li>Basics of React: JSX, Components, Props, and State</li>
                <li> Functional vs Class Components</li>
                <li>Event Handling and Conditional Rendering</li>
                <li>Forms, Controlled Components, and Validation</li>
                <li>React Router for Single Page Application (SPA) </li>
                <li>React Hooks (useState, useEffect, useContext, useRef)</li>
                <li>State Management (Redux, Context API)</li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Module 5: Node.js (Backend Environment)
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">Tools: Visual Studio Code, Node.js</li>
              <ul>
                <li>Introduction to Node.js and Event-driven Programming</li>
                <li>Working with File System and Streams</li>
                <li>Express.js with Node.js for REST API Development</li>
                <li>
                  Asynchronous Programming with Callbacks, Promises, and
                  Async/Await
                </li>
                <li>WebSockets and Real-time Communication (Socket.io)</li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>
            Module 6: Full-Stack Application Development
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">
                Tools: Git, GitHub, Heroku, Docker, Nginx
              </li>
              <ul>
                <li>
                  Connecting Frontend (React) with Backend (Node.js/Express) via
                  REST APIs
                </li>
                <li>
                  Handling Authentication & Authorization (JWT, Passport.js)
                </li>
                <li>Deployment on Cloud (Heroku, AWS, Netlify, Vercel)</li>
                <li>
                  Building Real-Time Applications (e.g., Chat App using
                  Socket.io)
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <h1 id="stu2">
          Data Structures & Algorithms (DSA) <span> </span>
        </h1>

        <Accordion.Item eventKey="6">
          <Accordion.Header>Module 1: Introduction to DSA</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Importance of Data Structures & Algorithms</li>
              <li>Time Complexity & Space Complexity (Big O Notation)</li>
              <li>Arrays and Strings: Traversing, Searching, Sorting</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>Module 2: Linked Lists</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Singly Linked List, Doubly Linked List, Circular Linked List
              </li>
              <li>Insertion, Deletion, Traversing</li>
              <li>Reversing a Linked List</li>
              <li>Detecting Cycles in Linked Lists</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>Module 3: Stacks and Queues</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Stack Operations (Push, Pop, Peek)</li>
              <li>Queue Operations (Enqueue, Dequeue)</li>
              <li>
                Applications of Stacks and Queues (Expression Evaluation,
                Parentheses Matching)
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9">
          <Accordion.Header>Module 4: Trees</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Binary Trees: In-order, Pre-order, Post-order Traversals</li>
              <li>Binary Search Trees (BST)</li>
              <li>AVL Trees, Red-Black Trees</li>
              <li>Trie Data Structure</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        {/* Certification Requirements */}
        <Accordion.Item eventKey="10">
          <Accordion.Header>Module 5: Graphs</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Representation of Graphs (Adjacency Matrix, Adjacency List)
              </li>
              <li>
                Graph Traversals: BFS (Breadth-First Search), DFS (Depth-First
                Search)
              </li>
              <li>Shortest Path Algorithms (Dijkstra, Bellman-Ford)</li>
              <li>Topological Sorting and Cycle Detection</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}
        <Accordion.Item eventKey="11">
          <Accordion.Header>Module 6: Sorting and Searching</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Linear Search and Binary Search</li>
              <li>
                Bubble Sort, Merge Sort, Quick Sort, Insertion Sort,Heap Sort
              </li>
              <li>Searching in Sorted Arrays, Rotated Arrays</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}
        <Accordion.Item eventKey="12">
          <Accordion.Header>Module 7: Dynamic Programming</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Concept of Overlapping Subproblems</li>
              <li>Memoization vs Tabulation</li>
              <li>
                Popular Dynamic Programming Problems (Fibonacci, Knapsack, Coin
                Change, Longest Common Subsequence)
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}
        <Accordion.Item eventKey="13">
          <Accordion.Header>Module 8: Advanced Topics</Accordion.Header>
          <Accordion.Body>
            <li className="points">
              Tools:IDEs like Visual Studio Code, IntelliJ, CodeWars, LeetCode,
              HackerRank
            </li>
            <ul>
              <li>Backtracking (Sudoku, N-Queens Problem)</li>
              <li>Greedy Algorithms (Activity Selection, Huffman Coding)</li>
              <li>Divide and Conquer Algorithms (Merge Sort, Quick Sort)</li>
              <li>Bit Manipulation</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}

        <h1 id="stu2">
          System Design <span> </span>
        </h1>
        <Accordion.Item eventKey="14">
          <Accordion.Header>
            Module 1: Introduction to System Design
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>What is System Design?</li>
              <li>
                Importance of Scalability, High Availability, and Performance
              </li>
              <li>Understanding of CAP Theorem, Load Balancing, Caching</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}
        <Accordion.Item eventKey="15">
          <Accordion.Header>
            Module 2: Designing Scalable Systems
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Horizontal vs Vertical Scaling</li>
              <li>Load Balancers and Reverse Proxies</li>
              <li>Caching Strategies (Redis, Memcached)</li>
              <li>Database Partitioning and Sharding</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}
        <Accordion.Item eventKey="16">
          <Accordion.Header>
            Module 3: High Availability and Fault Tolerance
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Concepts of Redundancy, Failover, Replication</li>
              <li>Multi-Region Deployment</li>
              <li>Disaster Recovery Planning</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}
        <Accordion.Item eventKey="16">
          <Accordion.Header>
            Module 4: Designing Real-World Systems
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Design a URL Shortener (e.g., bit.ly)</li>
              <li>Design a Messaging Service (e.g., WhatsApp)</li>
              <li>Design a Search Engine (e.g., Google)</li>
              <li>
                Design a Distributed File Storage System (e.g., Google Drive)
              </li>
              <li>Design a Social Media Platform (e.g., Twitter)</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="17">
          <Accordion.Header>
            Module 5: Microservices Architecture
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Introduction to Microservices</li>
              <li>Service Discovery, API Gateway</li>
              <li>Inter-Service Communication (REST, gRPC, Kafka)</li>
              <li>Data Consistency and Eventual Consistency</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}
        <Accordion.Item eventKey="18">
          <Accordion.Header>
            Module 6: Security in System Design
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Authentication and Authorization (OAuth 2.0, JWT)</li>
              <li>Data Encryption (SSL/TLS, AES)</li>
              <li>Secure APIs and Rate Limiting</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}
        <Accordion.Item eventKey="19">
          <Accordion.Header>
            Module 7: Performance Optimization and Monitoring
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Monitoring Tools (Prometheus, Grafana)</li>
              <li>Load Testing (JMeter, Apache Bench)</li>
              <li>Query Optimization and Indexing</li>
              <li>Memory and CPU Optimization</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}

        <h1 id="stu2">
          Tools & Software for Full Stack Development, DSA, and System Design
          <span> </span>
        </h1>

        <Accordion.Item eventKey="20">
          <Accordion.Header>Version Control & Collaboration</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Git, GitHub, GitLab</li>
              <li>CI/CD with Jenkins, Travis CI, CircleCI</li>
              <li>Jira, Trello (Project Management)</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* Certification Requirements */}
        <Accordion.Item eventKey="21">
          <Accordion.Header>Development & IDEs</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Visual Studio Code, Sublime Text, IntelliJ IDEA</li>
              <li>Postman (API Testing)</li>
              <li>Docker (Containerization)</li>
              <li>Nginx (Reverse Proxy, Load Balancing)</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="22">
          <Accordion.Header>Cloud & Deployment</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>AWS, Google Cloud, Microsoft Azure</li>
              <li>Heroku, DigitalOcean, Netlify</li>
              <li>Docker, Kubernetes (Container Orchestration)</li>
              <li>Terraform (Infrastructure as Code)</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="23">
          <Accordion.Header>Database Tools</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>MongoDB Atlas, MySQL, PostgreSQL, Redis</li>
              <li>MongoDB Compass, pgAdmin, Robo 3T</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="24">
          <Accordion.Header>Front-end & Design Tools</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>React Developer Tools</li>
              <li>Figma, Sketch (UI/UX Design)</li>
              <li>Webpack, Babel (Bundling & Transpiling)</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="25">
          <Accordion.Header>DSA Platforms</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>LeetCode, HackerRank, Codeforces, GeeksforGeeks</li>
              <li>CodeSignal, InterviewBit, TopCoder</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="26">
          <Accordion.Header>System Design Tools</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Lucidchart, Draw.io, Microsoft Visio (Diagrams)</li>
              <li>UML (Unified Modeling Language)</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="27">
          <Accordion.Header>Certification Requirements</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Assignments: Weekly exercises on MERN stack, DSA problems, and
                System Design scenarios.
              </li>
              <li>
                Project: Build a real-world project using MERN stack (e.g.,
                E-commerce platform, Blogging system, etc.)
              </li>
              <li>
                Final Exam: A comprehensive exam covering MERN stack, DSA, and
                System Design concepts.
              </li>
              <li>
                Certification: Upon successful completion of assignments,
                projects, and exams.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default ProgramDsa;
