import React from "react";
import "./FoundersMessage.css";
import rajesh from './aboutpic/image.png'

const FoundersMessage = () => {
  return (
    <div className="foundersmessage">
      <h2 className="foundersmessage-title">Meet Our Founder</h2>
      {/* <p className="foundersmessage-description">
        A Rajesh Kumar, the Person behind Bluink360 Solution, bring a wealth of knowledge, experience, and passion for teaching to their upskilling platform for tech engineers.
      </p> */}
      <div className="foundersmessage-profile">
        <img
          src={rajesh}
          alt="A Rajesh Kumar"
          className="foundersmessage-image"
        />
        <div className="foundersmessage-details">
          {/* <h3 className="foundersmessage-name">A Rajesh Kumar</h3> */}
          <p className="foundersmessage-bio">
          <span className="foundersmessage-titlyy">Rajesh Kumar,</span> the founder of Bluink360 Solution, brings over a decade of experience in the corporate and EdTech sectors. His expertise likely spans various aspects of business management, technology integration, and educational innovation. With his background, Rajesh is well-equipped to navigate the challenges of both the corporate world and the evolving EdTech industry. This combination of experience positions Bluink360 Solution to offer a range of solutions, from corporate training to educational tools and platforms that leverage the latest technological advancements. His leadership might emphasize growth, innovation, and the development of cutting-edge solutions for clients across industries.          </p>
          <div className="foundersmessage-footer">
            {/* <span className="foundersmessage-role">Ex. Quoori / Samsung</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoundersMessage;
