import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Form.css";

function Form() {
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_zzmvdkh", // Replace with your EmailJS service ID
        "template_abycbdo", // Replace with your EmailJS template ID
        {
          name: formData.name,
          mobile_number: formData.mobileNumber,
        },
        "JjLBlxjqw0QoNFo7L" // Replace with your EmailJS user ID (from the integration tab)
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
          setFormData({ name: "", mobileNumber: "" }); // Reset form
        },
        (error) => {
          alert("Failed to send message. Please try again.");
          console.error(error);
        }
      );
  };

  return (
    <>
      <div className="formdiv">
        <section className="formsec">
          <h1> Book a Consultation with Our Expert and Reserve Your Seat.</h1>

          <form className="appliform" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="mobileNumber"
              placeholder="Mobile Number"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
            <button type="submit" className="appliformbtn">
              Submit
            </button>
          </form>
          <p>
            By providing your contact details, you agree to
            <span> our Terms of Use & Privacy Policy</span>
          </p>
        </section>
      </div>
    </>
  );
}

export default Form;
