import React, { useEffect, useState } from "react";
import "./Alumni.css";
import Abhishek from '../../Assets/Alumni/AbhishekR.jpg'
import Akshatha from '../../Assets/Alumni/AkshathaN.jpg'
import Aqib from '../../Assets/Alumni/AqibJaveed.jpg'
import Ashwini from '../../Assets/Alumni/AshwiniN.jpg'
import Faraz from '../../Assets/Alumni/FarazAhmed.jpg'
import Harshal from '../../Assets/Alumni/HarshalaMishra.jpg'
import Harshitha from '../../Assets/Alumni/HarshithaM.jpg'
import SureshG from '../../Assets/Alumni/SureshG.jpg'


const alumniData = [
  { name: "Abhishek R", company: "Microsoft", linkedin: "#", img: Abhishek, logo: "https://upload.wikimedia.org/wikipedia/commons/2/20/LG_symbol.svg" },
  { name: "Akshatha", company: "DATAWEAVE", linkedin: "#", img: Akshatha, logo: "https://pbs.twimg.com/profile_images/1542727387179167746/eLQBiqSz_400x400.jpg" },
  { name: "Aqib Javeed", company: "Amazon", linkedin: "#", img: Aqib , logo: "https://cdn.oneorigin.us/wp-content/uploads/2021/12/OneOrigin-2022-Ring-Main.png" },
  { name: "Ashwini N", company: "Microsoft", linkedin: "#", img: Ashwini , logo: "https://cdn2.downdetector.com/static/uploads/logo/JP_Morgan_logo.png" },
  { name: "Faraz Ahmed", company: "Microsoft", linkedin: "#", img: Faraz , logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT96X0LPAyLGDQbkQiGK2C1DHpCP9InfSzdJA&s" },
  { name: "Harshala Mishra", company: "Microsoft", linkedin: "#", img: Harshal , logo: "https://w7.pngwing.com/pngs/1006/243/png-transparent-logo-robert-bosch-gmbh-alternator-product-electric-battery-bosch-text-trademark-logo-thumbnail.png" },
  { name: "Harshitha M", company: "Microsoft", linkedin: "#", img: Harshitha , logo: "https://pbs.twimg.com/profile_images/1542727387179167746/eLQBiqSz_400x400.jpg" },
  { name: "Suresh G", company: "Microsoft", linkedin: "#", img: SureshG , logo: "https://static.thearcweb.com/images/PROD/PROD-d8e5f0f6-ef80-4aa1-9a09-c12de42b1124.jpg" },
];


const Alumni = () => {
  const [getStudent, setGetStudent] = useState(alumniData);

  useEffect(() => {
    const scrollers = document.querySelectorAll(".alu-scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation(scrollers);
    }
  }, []);

  const addAnimation = (scrollers) => {
    scrollers.forEach((scroller) => {
      scroller.setAttribute("data-animated", true);
      const scrollerInner = scroller.querySelector(".alu-scroller__inner");
      const scrollerContent = Array.from(scrollerInner.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute("aria-hidden", true);
        scrollerInner.appendChild(duplicatedItem);
      });
    });
  };

  return (
    <div className="aluscrol">
      <h2 className="aluscrolh2">Bluink360 <span> Alumni </span> Network</h2>
      {/* Image Scroller */}
      <div className="alu-scroller" data-direction="right" data-speed="slow">
        {/* First div showing images in normal order */}
        <div className="alu-scroller__inner">
          {getStudent.map((e, index) => (
           <div key={index} className="student-info">
           <img className="aluimmg" src={e.img} alt={e.name} />
           <p>{e.name}</p>
           <img className="company-logo" src={e.logo} alt={`${e.company} logo`} />
           <a href={e.linkedin} target="_blank" rel="noopener noreferrer">
            <br></br> LinkedIn
           </a>
         </div>
         
          ))}
        </div>

        {/* Second div showing images in reverse order */}
        {/* <div className="alu-scroller__inner">
          {getStudent
            .slice()
            .reverse()
            .map((e, index) => (
              <div key={index} className="student-info">
                <img
                  className="aluimmg"
                  src={e.img}
                  alt={e.name}
                />
                <p>{e.name}</p>
                <p>{e.company}</p>
                <a href={e.linkedin} target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>
              </div>
            ))}
        </div> */}
      </div>
    </div>
  );
};

export default Alumni;
