import Navagation from "./Navigate";
import Footer from "./Components/Footer";

import Tech from "./Corperate/Technologies";
import CorpBan from "./Corperate/CopBan";
import CopTest from './Corperate/client'
import CorpFeatures from "./Corperate/corpfeat1";
import Corporatecontact from './Newchange/Corporatecontact'
function CorperateTraining() {
  return (
    <>
      <Navagation />
      <CorpBan />
      <Tech />
      <Corporatecontact />
      <CopTest />
      <CorpFeatures />
      <Footer />
    </>
  );
}
export default CorperateTraining;
