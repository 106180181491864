import "./Faqhomepage.css";

import Accordion from "react-bootstrap/Accordion";

function Faqhomepage() {
  return (
    <div className="AccordFaq">
      <h2 className="accordh2">Frequently Asked Questions ! </h2>

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            1. What courses does Bluink360 Solution offer?
          </Accordion.Header>
          <Accordion.Body>
            Bluink360 Solution specializes in MERN Stack Development, Data
            Structures & Algorithms (DSA), and System Design. These courses are
            tailored for students and professionals aspiring to excel in software
            development and IT roles.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            2. What is the mode of training at Bluink360 Solution?
          </Accordion.Header>
          <Accordion.Body>
            We offer both online and offline training. Our offline sessions are
            conducted at our Bangalore center, while online sessions are live
            and interactive, ensuring flexibility for learners from anywhere.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            3. Who are the instructors at Bluink360 Solution?
          </Accordion.Header>
          <Accordion.Body>
            Our instructors are industry veterans with extensive expertise in
            MERN Stack, DSA, and System Design. They bring hands-on experience
            from top tech companies and deliver practical, project-based
            training.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            4. Do I receive a certificate after completing a course?
          </Accordion.Header>
          <Accordion.Body>
            Yes, upon successfully completing the course and passing
            assessments, you will receive an industry-recognized certificate
            from Bluink360 Solution, enhancing your resume and job prospects.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            5. Does Bluink360 Solution provide placement assistance?
          </Accordion.Header>
          <Accordion.Body>
            Yes, we provide end-to-end placement support, including resume
            building, mock interviews, and direct connections to hiring
            companies. Many of our students have secured roles in top
            organizations.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            6. What are the prerequisites for enrolling in a course?
          </Accordion.Header>
          <Accordion.Body>
            For beginner courses like MERN Stack Development, no prior
            experience is required. However, for advanced System Design and DSA
            courses, some familiarity with programming is recommended.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            7. How do I enroll in a course?
          </Accordion.Header>
          <Accordion.Body>
            You can enroll through our website or visit our Bangalore center for
            offline registrations. For assistance, you can contact our support
            team via phone or email.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            8. Do you offer any scholarships or discounts?
          </Accordion.Header>
          <Accordion.Body>
            Yes, we offer merit-based scholarships and promotional discounts.
            Reach out to our team or check our website for the latest offers and
            eligibility criteria.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            9. How long does it take to complete a course?
          </Accordion.Header>
          <Accordion.Body>
            The duration varies: MERN Stack Development typically takes 2-3
            months, while DSA and System Design may take 3-4 months. We also
            offer fast-track options for working professionals.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>
            10. Can I access course materials after the program ends?
          </Accordion.Header>
          <Accordion.Body>
            Yes, students gain access to recorded sessions, notes, and
            additional resources even after completing the course. Some
            materials come with lifetime access.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header>
            11. What payment methods are accepted?
          </Accordion.Header>
          <Accordion.Body>
            We accept credit cards, debit cards, net banking, UPI, and EMI
            options for select courses. Offline payments can also be made at
            our Bangalore center.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="11">
          <Accordion.Header>
            12. How can I contact Bluink360 Solution for more information?
          </Accordion.Header>
          <Accordion.Body>
            You can contact us via our website's contact form, email, or phone.
            Our support team is based in Bangalore and available during business
            hours to address all your queries.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default Faqhomepage;
