import React from "react";
import "./Combocourse.css"; // CSS file for styling
import { NavLink } from "react-router-dom";

const Combocourse = () => {
  const courses = [
    {
      title: "Full Stack Web Developer",
      items: [
        { name: "NodeJS", icon: "https://img.icons8.com/?size=50&id=54087&format=png&color=000000" },
        { name: "React JS", icon: "https://img.icons8.com/color/48/react-native.png" },
        { name: "MongoDB", icon: "https://img.icons8.com/color/48/database.png" },
        { name: "DevOPS", icon: "https://img.icons8.com/?size=40&id=CLa3T2WlbrOP&format=png&color=000000" },
      ],
      img: "https://img.icons8.com/?size=50&id=DzQFf09l3D1p&format=png&color=000000",
      validity: "2 Months",
    },
    {
      title: "Master in DSA",
      items: [
        { name: "Data Structures", icon: "https://img.icons8.com/color/48/adobe-illustrator.png" },
        { name: "Algorithms", icon: "https://img.icons8.com/color/48/adobe-photoshop.png" },
        { name: "Problem Solving", icon: "https://img.icons8.com/?size=50&id=52954&format=png&color=000000" },
        { name: "Complexity Analysis", icon: "https://img.icons8.com/?size=50&id=zI5lA8GyrIQK&format=png&color=000000" },
      ],
      img: "https://img.icons8.com/?size=50&id=DzQFf09l3D1p&format=png&color=000000",
      validity: "4 Months",
    },
    // {
    //   title: "Business Combo Pack",
    //   items: [
    //     { name: "Stock Market", icon: "https://img.icons8.com/color/48/stocks.png" },
    //     { name: "FRM", icon: "https://img.icons8.com/color/48/certificate.png" },
    //     { name: "Technical Research", icon: "https://img.icons8.com/?size=50&id=123382&format=png&color=000000" },
    //     { name: "Financial Modelling Valuation", icon: "https://img.icons8.com/?size=50&id=8sPd3xZZmzd4&format=png&color=000000" },
    //   ],
    //   img: "https://img.icons8.com/?size=50&id=DzQFf09l3D1p&format=png&color=000000",
    //   validity: "2 Years",
    // },
    {
      title: "Our System Design Includes",
      items: [
        { name: "Scalability & Performance", icon: "https://img.icons8.com/color/48/artificial-intelligence.png" },
        { name: "Data Management", icon: "https://img.icons8.com/color/48/sql.png" },
        { name: "Architecture Components", icon: "https://img.icons8.com/?size=50&id=36627&format=png&color=000000" },
        { name: "Reliability & Security", icon: "https://img.icons8.com/?size=50&id=CTghwBAVBDpZ&format=png&color=000000" },
      ],
      img: "https://img.icons8.com/?size=50&id=DzQFf09l3D1p&format=png&color=000000",
      validity: "1 month",
    },
    {
      title: "Mock interview & Profile Building",
      items: [
        { name: "Resume Customization", icon: "https://img.icons8.com/?size=50&id=z6faRXwXtVdE&format=png&color=000000" },
        { name: "Project Showcasing", icon: "https://img.icons8.com/?size=50&id=qbukS3CFF3w8&format=png&color=000000" },
        { name: "Skill Highlighting", icon: "https://img.icons8.com/?size=50&id=65773&format=png&color=000000" },
        { name: "Mock Interviews", icon: "https://img.icons8.com/?size=50&id=EHG6YBCywXLR&format=png&color=000000" },
      ],
      img: "https://img.icons8.com/?size=50&id=DzQFf09l3D1p&format=png&color=000000",
      validity: "1 month",
    },
  ];
  

  return (
    <div className="fixingsizeing">
    <div className="Combocourse-container">
       <h1 className="title">Our <span className="highlight">MERN + DSA and System Design</span> Master Certification covers: </h1>
      <div className="Combocourse-scroll">
        {courses.map((course, index) => (
          <div className="Combocourse-card" key={index}>
            <div className="Combocourse-header">
              <div className="icon-placeholder">
                <img
                  src={course.img || "https://img.icons8.com/color/48/book.png"}
                  alt="course icon"
                />
              </div>
              <h3>{course.title}</h3>
            </div>
            <ul className="Combocourse-items">
              {course.items.map((item, idx) => (
                <li key={idx} className="Combocourse-item">
                  <span className="item-icon">
                    <img
                      src={item.icon || "https://img.icons8.com/color/48/question-mark.png"}
                      alt={`${item.name} icon`}
                    />
                  </span>
                  {item.name}
                </li>
              ))}
            </ul>
            <div className="Combocourse-footer">
  <p>
    <div className="combocourseborder">
      <i className="refund-info-icon"></i><center> {course.validity}</center>
    </div>
  </p>
  {/* <button className="Combocourse-button">Enroll Now</button> */}
</div>


          </div>
          
        ))}
        
      </div>
      
      <NavLink to="/Course">
            <button className="Combocourse-button">Know Your Course</button></NavLink>
    </div>
    </div>
  );
};

export default Combocourse;
