import React, { useEffect, useState } from "react";
import "./Aboutalumnifeed.css";

const alumniFeedback = [
  {
    name: "Aditya Singh",
    college: "Vellore Institute of Technology",
    company: "Google",
    feedback:
      "Before joining Bluink360, I struggled to get hands-on experience in full-stack development. The MERN stack course gave me the confidence and skills to work on live projects and secure my first job.",
  },
  {
    name: "Pooja Sharma",
    college: "SRM Institute of Science and Technology",
    company: "Capgemini",
    feedback:
      "Bluink360's DSA sessions were a game changer for me. The structured learning and regular problem-solving sessions helped me crack coding interviews with ease.",
  },
  {
    name: "Rahul Nair",
    college: "Anna University",
    company: "TCS",
    feedback:
      "The combination of MERN stack training and mock interviews at Bluink360 made me industry-ready. I was able to build a strong portfolio and land a great opportunity.",
  },
  {
    name: "Ishita Desai",
    college: "Delhi University",
    company: "Infosys",
    feedback:
      "The personalized mentoring and coding challenges helped me strengthen my understanding of core subjects and ace my interviews. Bluink360 was instrumental in my career success.",
  },
  // Add more items as needed
];

const AlumniFeed = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        (prevIndex + 2) % alumniFeedback.length
      ); // Move two items forward, loop back to start
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const getVisibleFeedback = () => {
    // Dynamically calculate visible items
    const firstCard = alumniFeedback[currentIndex];
    const secondCard =
      alumniFeedback[(currentIndex + 1) % alumniFeedback.length];
    return [firstCard, secondCard];
  };

  const visibleFeedback = getVisibleFeedback();

  return (
    <div className="Aboutalumnifeed">
      <h2>
      Discover our students' <span className="highlight"> Feedback </span> and <span className="highlight">  Success Stories </span>
      </h2>
      <div className="testimonial-container">
        {visibleFeedback.map((feedback, index) => (
          <div className="testimonial" key={index}>
            <p>"{feedback.feedback}"</p>
            <hr />
            <h4 className="alumnicenterwala">{feedback.name}</h4>
            <p>
              {feedback.college} &rarr;{" "}
              <span className="company">{feedback.company}</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlumniFeed;
