import React from "react";
import "./CopBan.css"; // Optional: Move styles to a separate CSS file
import { motion } from "framer-motion";
import Traning from "../../Assets/Train.png";

const CorpBan = () => {
  const textAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <div>
      {/* Main container with two sections */}
      <motion.div
        initial="hidden"
        animate="visible"
        transition={{ duration: 1.2 }}
        variants={textAnimation}
        className="corporate-banner-text"
      >
        <div className="flex-container">
          <div className="content-primary">
            <h2>
              {/* New skills for <span> Digital Transformation</span>{" "} */}
              Revolutionizing Training with Customized, Engaging Learning Solutions
            </h2>
            <p>
            Bluink360’s Instructor-Led Live Online Training services offer a dynamic and interactive learning experience that combines the benefits of traditional classroom instruction with the flexibility of online learning.<br></br><br></br>

This service is designed to deliver high-quality training in real-time, allowing participants to engage directly with expert instructors and collaborate with peers, all from the convenience of their own locations.
            </p>
            <button>Start Free Demo</button>
          </div>
          <div className="content-secondary">
            <img src={Traning} alt="" />
          </div>
        </div>

        {/* Blueprint section with three items */}
        <div className="item">
          <div className="blueprint-section">
            <div className="blueprint-item">
              <h1>2500+</h1> <h2>Professionals trained</h2>
            </div>
            <div className="blueprint-item">
              <h1>500+</h1> <h2>Happy Clients</h2>
            </div>
            <div className="blueprint-item">
              <h1>17+</h1> <h2>Years of experience</h2>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CorpBan;
