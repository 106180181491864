import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com"; // Import emailjs-com
import "./Pricing.css";
import ondsa from "../../Assets/OnlineDSA.png";
import ofdsa from "../../Assets/OfflineDSA.png";

const courseData = [
  {
    id: 1,
    name: "Master MERN + DSA & System Design with certification",
    image: ondsa,
    cert: "ISO TSN Certified",
    jobAssistance: "100% Job Assistance",
    duration: "1:1 Mentorship",
    salary: "18LPA + Average",
    projects: "Real-World Projects",
    mentors: "Mentors from Top MNC",
    support: "24/7 Learning Support",
    mode: "₹99,999",
    mode1: "₹1,49,999",
    price: 149999,
    brochure: "https://bluink360.com/pdfdata/Bluink360_DSA_Course.pdf",
  },
  {
    id: 2,
    name: "Master MERN + DSA & System Design with certification",
    image: ofdsa,
    cert: "ISO TSN Certified",
    jobAssistance: "100% Job Assistance",
    duration: "1:1 Mentorship",
    salary: "18LPA + Average",
    projects: "Real-World Projects",
    mentors: "Mentors from Top MNC",
    support: "24/7 Learning Support",
    mode: "₹1,49,999",
    mode1: "₹1,99,999",
    price: 199999,
    brochure: "https://bluink360.com/pdfdata/Bluink360_DSA_Course.pdf",
  },
];

function CourseCard() {
  const [showModal, setShowModal] = useState(false);
  const [selectedBrochure, setSelectedBrochure] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
  });
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleBrochureClick = (brochureLink) => {
    setSelectedBrochure(brochureLink);
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the form is submitted

    // Send email using EmailJS
    emailjs
      .send(
        "service_g9uo8nq", // Replace with your service ID
        "template_n6jmnyc", // Replace with your template ID
        formData,
        "mCWwpUYcmFY2sWUd4" // Replace with your user ID
      )
      .then(
        (response) => {
          console.log("Email sent successfully", response);
          // Redirect to brochure after form submission
          setTimeout(() => {
            window.open(selectedBrochure, "_blank"); // Open brochure link
            setShowModal(false); // Close the modal
            setLoading(false); // Set loading to false after the redirect
          }, 2000); // Delay for 2 seconds to show the loading state
        },
        (error) => {
          console.error("Error sending email", error);
          setLoading(false); // Set loading to false in case of error
        }
      );
  };

  const textstyle = {
    color: "orange",
    fontSize: "18px",
  };

  const handlePayment = (course) => {
    if (!window.Razorpay) {
      alert("Razorpay SDK not loaded. Please try again later.");
      return;
    }

    const options = {
      key: "rzp_live_X7WnUIRgMTFglX", // Replace with your Razorpay key
      amount: course.price * 100, // Amount in paise
      currency: "INR",
      name: "Bluink360",
      description: `Payment for ${course.name}`,
      image: "https://bluink360.com/logo.png", // Add your logo URL
      handler: function (response) {
        alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
      },
      prefill: {
        name: "Your Name", // Replace with user data if available
        email: "user@example.com", // Replace with user data
        contact: "9876543210", // Replace with user data
      },
      theme: {
        color: "#3399cc",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();

    razorpay.on("payment.failed", function (response) {
      alert(`Payment failed! Error: ${response.error.description}`);
      console.error("Payment Failure Details:", response.error);
    });
  };

  return (
    <div className="course-section">
      <h1 className="pri-skillbuild-title">Our Courses</h1>
      <div className="pri-coursecon">
        {courseData.map((course) => (
          <div className="pri-dsa-course-card" key={course.id}>
            <img
              src={course.image}
              alt={`${course.name} Icon`}
              className="pri-course-image"
            />
            <div className="pri-dsa-content">
              <span className="pri-dsa-nsdc-cert">{course.cert}</span>
              <h3 className="pri-course-title">{course.name}</h3>
              <h6 style={textstyle}>
                <i className="fas fa-clock"> </i> 6-8 Month Course
                <br />
              </h6>
              <div className="pri-dsa-info">
                <div>
                  <i className="fas fa-briefcase"></i> {course.jobAssistance}
                </div>
                <div>
                  <i className="fas fa-user-tie"></i> {course.duration}
                </div>
              </div>
              <div className="pri-dsa-info">
                <div>
                  <i className="fas fa-money-bill-wave"></i> {course.salary}
                </div>
                <div>
                  <i className="fas fa-project-diagram"></i> {course.projects}
                </div>
              </div>
              <div className="pri-dsa-info">
                <div>
                  <i className="fas fa-user-tie"></i> {course.mentors}
                </div>
                <div>
                  <i className="fas fa-comments"></i> {course.support}
                </div>
              </div>
              <span className="pri-dsa-online-badge">
                <h6> {course.mode1} </h6>
              </span>
              <br />
              <h6>Scholarship Upto 30%</h6>
              <button
                className="pri-go-button"
                onClick={() => handlePayment(course)}
              >
                Enroll Now
              </button>

              <button
                className="pri-brochure-link"
                onClick={() => handleBrochureClick(course.brochure)}
              >
                BROCHURE <i className="fas fa-download"></i>
              </button>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Fill out this form to access the brochure</h2>
            <form onSubmit={handleFormSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Mobile Number:
                <input
                  type="tel"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>

            {loading && (
              <div className="loading-container">
                <div className="spinner"></div>
                <p>Please wait, we are redirecting...</p>
              </div>
            )}

            <button
              className="close-button"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseCard;
