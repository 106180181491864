import React from "react";
import "./Tools.css"; // CSS for styling
import {
  FaHtml5,
  FaJs,
  FaReact,
  FaNodeJs,
  FaCog,
  FaStackExchange,
  FaSortAmountUp,
  FaSearch,
  FaNetworkWired, // Added missing import for FaNetworkWired
} from "react-icons/fa";
import {
  SiRedux,
  SiMongodb,
  SiExpress,
  SiGooglecloud,
  SiDocker,
  SiKubernetes,
} from "react-icons/si";
import { GiTreeBranch, GiCpu, GiBrain } from "react-icons/gi"; // Added missing imports for tree and CPU icons

const skills = [
  { name: "HTML & CSS", icon: <FaHtml5 /> },

  { name: "JavaScript", icon: <FaJs /> },
  { name: "React", icon: <FaReact /> },
  { name: "Redux", icon: <SiRedux /> },

  { name: "Node", icon: <FaNodeJs /> },
  { name: "Express", icon: <SiExpress /> },
  { name: "MongoDB", icon: <SiMongodb /> },

  { name: "DSA", icon: <FaCog /> },
  { name: "Graph", icon: <FaNetworkWired /> },
  { name: "Tree", icon: <GiTreeBranch /> },
  { name: "Stack", icon: <FaStackExchange /> },
  { name: "Queue", icon: <SiGooglecloud /> },
  { name: "Sorting", icon: <FaSortAmountUp /> },
  { name: "Searching", icon: <FaSearch /> },
  { name: "System Design", icon: <GiCpu /> },
  { name: "Docker", icon: <SiDocker /> },
  { name: "Kubernetes", icon: <SiKubernetes /> },
  { name: "DSA - Brain", icon: <GiBrain /> }, // DSA Brain icon
];

const Skills = () => {
  return (
    <section className="skills-section">
      <h1 id="Tools">Tools and Technologies</h1>
      <div className="skills-grid">
        {skills.map((skill, index) => (
          <div className="skill-card" key={index}>
            <div className="skill-icon">{skill.icon}</div>
            <p className="skill-name">{skill.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
