import React from "react";
import "./copTest.css"; // Import the CSS file

const testimonialsData = [
  {
    id: 1,
    name: "Mr. Rangarajan",
    title: "Businessman",
    feedback:
      "Bluink360 revolutionized our online training with an engaging, skill-building program. Their user-friendly platform and real-world exercises allowed us to learn at their own pace.",
    image: "https://bluink.awsin1.cdn-alpha.com/wp-content/uploads/2024/08/Testimonial1-480x480.jpg", // Replace with the actual image path
  },
  {
    id: 2,
    name: "Mr. Chandra",
    title: "Businessman",
    feedback:
      "Partnering with Bluink360 transformed our training approach. Their user-friendly, engaging modules helped our team easily acquire new skills, with flexible solutions that fit seamlessly into our workflow.",
    image: "https://bluink.awsin1.cdn-alpha.com/wp-content/uploads/2024/08/Testimonial2-480x480.jpg", // Replace with the actual image path
  },
  {
    id: 3,
    name: "Ms. Asha Naik",
    title: "Businesswoman",
    feedback:
      "Working with Bluink360 was exceptional. They understood our goals, creating a content-rich, engaging course that exceeded expectations. Delivered on time & got outstanding feedback.",
    image: "https://bluink.awsin1.cdn-alpha.com/wp-content/uploads/2024/08/Testimonial3-480x480.jpg", // Replace with the actual image path
  },
];

const copTest = () => {
  return (
    <section className="testimonials">
      <h2 className="title">Testimonials</h2>
      <h3 className="subtitle">Happy Clients & Feedbacks</h3>
      <div className="testimonial-cards">
        {testimonialsData.map((testimonial) => (
          <div className="card" key={testimonial.id}>
            <center>
              <img
                src={testimonial.image}
                alt={'${testimonial.name}'}
                className="profile-picture"
              />
            </center>
            <p className="feedback">"{testimonial.feedback}"</p>
            <h4 className="client-name">{testimonial.name}</h4>
            <p className="client-title">{testimonial.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default copTest;