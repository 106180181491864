import React, { useState, useEffect } from "react";
import Header from "./Components/Header";
import Navagation from "../Bluink/Navigate";
import Banner from "./Components/Banner";
import Verified from "./Components/Verified";

import Alumni from "./Components/Alumni";

import Companieslist from "./Components/Companies";
import HaveQuestion from "./Components/Question";
import News from "./Components/News";
import CodeEditor from "./Components/CodeEditor";
import Footer from "./Components/Footer";

import CourseCard from "./Components/Coursecard";
import NewMentorCard from "./Components/NewMentor";
import Blogs from "./Blogs";
import Whychcombo from "./Newchange/Whychcombo";
import ComboCourseCompare from "./Newchange/Combocompare";
import Combocourse from "./Newchange/ComboDesign";
import Combocourseimp from "./Newchange/Combocourseimp";
import AboutTheTeam from "./Newchange/AboutTheTeam";
import Faqhomepage from "./Newchange/Faqhomepage";
import Aboutprofile from "./Newchange/Aboutprofile";
import Aboutfeed from './Newchange/Aboutalumnifeed'
import Pricing from './Components/Pricing'
import AlumniCard from "./Newchange/Alumninewdes1";
import Ourcollabration from "./Newchange/Ourcollabration";
import Form from "./Components/Form";




// import Popup from "./Components/Popup"; // Import Popup component

function Home() {
  // const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    // const timer = setTimeout(() => {
    //   setShowPopup(true);
    // }, 5000); // Show popup after 5 seconds

    // return () => clearTimeout(timer);
  }, []);

  // const closePopup = () => {
  //   setShowPopup(false);
  // };

  return (
    <>
      <Header />
      <Navagation />
      <Banner />
      <div style={{ backgroundColor: "#f0f7ff" }}>
        {/* <Verified />
        <CodeEditor /> */}
       {/* <Combocourseimp /> */}
      
        {/* <CourseCard /> */}
        <Pricing />
        <Combocourse />
     
        <Alumni />
        <Ourcollabration />
        <Companieslist />
     
        {/* <NewMentorCard /> */}
        <AboutTheTeam />
        <Aboutfeed />
       
        <Whychcombo />
      
        <News />
        {/* <HaveQuestion /> */}
        {/* <Blogs /> */}
        <Aboutprofile />
        <Form />
        <Faqhomepage />
      </div>

      <Footer />

      {/* Display Popup if showPopup is true */}
      {/* {showPopup && <Popup closePopup={closePopup} />} */}
    </>
  );
}

export default Home;
